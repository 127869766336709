<template>
  <div class="page">
    <!-- 开票信息 -->
    <a-form-model style="width:100%;border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin-bottom:16px;">
      <div class="ant-descriptions-title">销售方信息</div>
      <div style="display: flex;">
        <a-form-model-item label="销售方信息" required style="width: 30%;">
          <div style="position: relative;">
            <a-select v-model="form.sellerId" @change="checkoutSeller" style="width: 100%" placeholder="请选择销售方信息"
              disabled>
              <a-select-option :value="item.id" v-for="item in sellerList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </a-form-model-item>

        <a-form-model-item label="发票类型" required style="width: 30%;margin:0 20px;">
          <div style="position: relative;">
            <a-select disabled v-model="form.invoiceTypeId" style="width: 100%"
              placeholder="请选择发票类型">
              <a-select-option :value="item.id" v-for="item in TypeList" :key="item.id"
                @click="invoiceTypeChange(item.invoiceType)">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </a-form-model-item>

      </div>
    </a-form-model>

    <!-- 发票信息 -->
    <div class="inv-box">
      <a-form-model style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin-bottom:16px;">
        <div class="ant-descriptions-title">买方信息</div>
        <div style="width:100%;display: flex;flex-wrap:wrap;">
          <a-form-model-item label="公司名称" required style="width: 22%;">
            <span>{{ form.sfaInvoice.invoiceHeading }}</span>
          </a-form-model-item>
          <a-form-model-item label="发票抬头" required style="width: 22%;">
            <span>{{ form.sfaInvoice.invoiceHeading }}</span>
          </a-form-model-item>
          <a-form-model-item label="统一社会信用代码/纳税人识别号" required style="width: 22%;">
            <span>{{ form.sfaInvoice.dutyParagraph }}</span>
          </a-form-model-item>
          <a-form-model-item label="开户行" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ form.sfaInvoice.bank }}</span>
          </a-form-model-item>
          <a-form-model-item label="发票类型" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ form.sfaInvoice.invoiceTypeName }}</span>
          </a-form-model-item>
          <a-form-model-item label="账号" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ form.sfaInvoice.bankAccount }}</span>
          </a-form-model-item>
          <a-form-model-item label="地址" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ form.sfaInvoice.address }}</span>
          </a-form-model-item>
          <a-form-model-item label="电话" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ form.sfaInvoice.phone }}</span>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>

    <!-- 开票明细 -->
    <a-form-model style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin:16px 0;">

      <infoTable :data="sfaInvoiceApplyDetailsContentList" :sellerId="this.form.sellerId"
        @sendCententList="sendCententList"></infoTable>
      <div style="height: 15px"></div>
      <div style="font-weight: 700;color:#000;margin-bottom: 10px;">
        开票金额：<span style="color:red;">¥{{ form.actualAmount }}</span>
      </div>
      <a-form-item label="发票备注">
        <a-textarea v-model="form.remarks" :disabled="this.$route.query.isDetail"
          :placeholder="this.$route.query.isDetail ? '无备注' : '1、该备注信息会展示在发票上展示，如有需要请录入，不需要则无需填写！2、建议不要超过25个汉字或50个（数字+字母），否则盖章的时候有可能压到！'"></a-textarea>
      </a-form-item>
    </a-form-model>

    <a-form-model style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin:16px 0;">
      <div class="ant-descriptions-title">销售单明细</div>
      <a-table :columns="columns" :data-source="form.sfaInvoiceApplyDetailsList" :pagination="false" bordered>
        <template slot="createTime" slot-scope="text">
          <p>{{ formatDateMin(text) }}</p>
        </template>
      </a-table>
    </a-form-model>

    <a-form-model style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin:16px 0;">
      <div class="ant-descriptions-title">发票附件</div>
      <embed v-if="invoiceSrc" :src="invoiceSrc" type="application/pdf" width="100%" height="600px" />
      <span v-else>未上传发票</span>
    </a-form-model>

    <!-- 说明 -->
    <div class="remarks-box">
      <div class="ant-descriptions-title">开票申请说明</div>
      <a-textarea v-model="form.applyExplain" :disabled="this.$route.query.isDetail"
        :placeholder="this.$route.query.isDetail ? '无说明' : '仅应用于内部审批备注，发票不展示！'"></a-textarea>
    </div>

    <!--驳回原因-->
    <div class="remarks-box" v-if="this.$route.query.isDetail && form.applyStatus == 5">
      <div class="ant-descriptions-title">驳回原因</div>
      {{form.rejectReason ? form.rejectReason : '-'}}
    </div>

  </div>
</template>
<script>
import { request } from "@/utils/request";
import infoTable from './components/invoiceInfoTable.vue'

export default {
  components: { infoTable },
  data() {
    return {
      columns: [{
        title: "类型",
        dataIndex: "invoicingTypeName",
        scopedSlots: { customRender: "invoicingTypeName" },
        align: "center",
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        scopedSlots: { customRender: "createTime" },
        align: "center",
      },
      {
        title: "订单号",
        dataIndex: "detailCode",
        scopedSlots: { customRender: "detailCode" },
        align: "center",
      },
      {
        title: "开票金额",
        dataIndex: "amount",
        scopedSlots: { customRender: "amount" },
        align: "center",
      },
      ],//销售单
      customerData: [],//客户列表
      invoiceList: [],//发票
      dataSource: [],//销售单列表
      resDataSource: [],//宅配列表
      queryData: {
        page: 0,
        size: 20,
        // f5b8a7ae-2bf6-4265-bb73-817ad6ebc17b
      },

      sellerList: [],//主体公司
      // 列表总页数
      total: 0,
      // 列表加载中
      tabLoading: false,
      tabOutLoading: false,
      resLoading: false,
      // 货堆列表参数
      pileParams: {
        page: 0,
        size: 10,
        type: 20,
        buyCustomerId: ""
      },
      // 宅配列表参数
      resParams: {
        page: 0,
        size: 10,
        type: 21,
        buyCustomerId: ""
      },
      selectInvoice: {},//选中的发票信息
      TypeList: [],
      // 提交表单
      form: {
        amount: 0,//开票金额
        sfaInvoiceApplyDetailsList: [],
      },
      tempSfaInvoiceApplyDetailsList: [],//接收子组件传来的sfaInvoiceApplyDetailsList
      invoiceType: 0,//发票类型
      invoiceAmount: 0,//可开票金额
      glAmout: '',//关联开票金额
      totalActualAmount: 0,//合计明细开票金额
      sfaInvoiceApplyDetailsContentList: [],
      invoicingType: 1,
      EditSfaInvoiceApplyDetailsList: [],
      invoiceSrc:""
    };
  },
  mounted() {
    this.getUser();//客户列表
    this.getSellerList();//主体公司
    // 详情
    this.getDetails();
  },
  watch: {
    'form.sellerId': function (newVal, oldValue) {
      console.log(newVal, oldValue, 'newVal');
      this.getInoviceTypeList();
    }
  },
  computed: {
    rowSelection1() {
      return {
        selectedRowKeys: this.selectedRowsKeys1,
        onChange: this.onSelectChange1,
        getCheckboxProps: record => ({
          props: {
            disabled: record.surplusAmount <= 0,
          },
        }),
      };
    },
    rowSelection2() {
      return {
        selectedRowKeys: this.selectedRowsKeys2,
        onChange: this.onSelectChange2,
        getCheckboxProps: record => ({
          props: {
            disabled: record.surplusAmount <= 0,
          },
        }),
      };
    },
    rowSelection3() {
      return {
        selectedRowKeys: this.selectedRowsKeys3,
        onChange: this.onSelectChange3,
        getCheckboxProps: record => ({
          props: {
            disabled: record.surplusAmount <= 0,
          },
        }),
      };
    },
  },
  methods: {
    getDetails() {
      if (this.$route.query.id) {
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/' + this.$route.query.id, 'get').then(res => {
          this.form = res.data.data;
          console.log(this.form, 'this.form')
          this.EditSfaInvoiceApplyDetailsList = this.form.sfaInvoiceApplyDetailsList
          this.queryData.customerId = this.form.customerId;
          this.queryData.invoiceId = this.form.invoiceId;
          // 开票明细
          this.sfaInvoiceApplyDetailsContentList = this.form.sfaInvoiceApplyDetailsContentList;
          // 明细开票金额
          this.sfaInvoiceApplyDetailsContentList.forEach(item => {
            this.totalActualAmount = this.totalActualAmount + item.money
          })
          // 获取该客户发票列表
          this.getInoviceList();
          if (this.$route.query.type == 1) {
            this.editList();
            this.editPileData()
            this.editResData()
            // this.getPileList();//货堆
            // this.getResList();//宅配
          }
          if(this.form.invoiceUrl) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/download', 'post', { soundRecordingUrl: this.form.invoiceUrl }).then(res => {
              if (res.data) {
                  const link = document.createElement('a');
                  link.href = res.data;
                  // 根据文件类型设置合适的文件名和扩展名
                  if (res.data.includes('.pdf')) {
                      link.download = 'document.pdf';
                  } else if (res.data.includes('.jpg')) {
                      link.download = 'image.jpg';
                  } else {
                      // 默认情况下设置一个通用的文件名和扩展名
                      link.download = 'file';
                  }
                  this.invoiceSrc = res.data;
                  // link.style.display = 'none';
                  // document.body.appendChild(link);
                  // link.click();
                  // document.body.removeChild(link);
              } else {
                  this.$message.error(res.data);
              }
            });
          }
         
          // 关联
          if (this.$route.query.status) {
            this.invoicingType = this.$route.query.type;
            this.glAmout = this.form.actualAmount
            this.getList();
            setTimeout(() => {
              this.selectInvoiceChange(this.form.invoiceId);
            }, 1500)
          }
        });
      }
    },
    invoiceTypeChange(type) {
      this.invoiceType = type;
    },
    editList() {
      this.getList().then(() => {
        this.selectInvoiceChange(this.form.invoiceId);
        let idxArr = [];
        let newArr = []
        this.form.invoiceAmount = this.form.amount;
        // 开票明细 绑定
        console.log(this.dataSource, 'this.dataSource')
        console.log(this.form.sfaInvoiceApplyDetailsList, 'this.form.sfaInvoiceApplyDetailsList')
        this.EditSfaInvoiceApplyDetailsList.forEach(item => {
          this.dataSource.forEach((da, i) => {
            if (da.code == item.detailCode) {
              idxArr.push(i);
              newArr.push(da)
              this.onSelectChange1(idxArr, newArr)
            }
          })
          // item.invoiceAmount = item.documentAmount;
          // item.sellerId = this.form.sellerId;
          // idxArr.push(index);
          // newArr.push(item)
          // this.onSelectChange1(idxArr,newArr)
        });
      });
    },
    editPileData() {
      // 货堆编辑初始化
      this.getPileList().then(() => {
        this.selectInvoiceChange(this.form.invoiceId);
        let idxArr = [];
        let newArr = []
        this.form.invoiceAmount = this.form.amount;
        // 开票明细 绑定
        console.log(this.pileDataSource, 'this.pileDataSource')
        console.log(this.form.sfaInvoiceApplyDetailsList, 'this.this.form.sfaInvoiceApplyDetailsList')
        this.EditSfaInvoiceApplyDetailsList.forEach(item => {
          this.pileDataSource.forEach((da, i) => {
            if (da.code == item.detailCode) {
              idxArr.push(i);
              newArr.push(da)
              this.onSelectChange2(idxArr, newArr)
            }
          })
        });
      });
    },
    editResData() {
      // 宅配编辑初始化
      this.getResList().then(() => {
        this.selectInvoiceChange(this.form.invoiceId);
        let idxArr = [];
        let newArr = []
        this.form.invoiceAmount = this.form.amount;
        // 开票明细 绑定
        this.EditSfaInvoiceApplyDetailsList.forEach(item => {
          this.resDataSource.forEach((da, i) => {
            if (da.code == item.detailCode) {
              idxArr.push(i);
              newArr.push(da)
              this.onSelectChange3(idxArr, newArr)
            }
          })
        });
      });
    },
    checkoutSeller(e) {
      this.form.sellerId = e;
      delete this.form.invoiceTypeId
      this.getInoviceTypeList();
    },

    checkEditable(arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].sfaInvoiceApplyDetailsContentList.length; j++) {
          if (arr[i].sfaInvoiceApplyDetailsContentList[j].editable) {
            this.$message.warning('请保存发票明细！')
            return false;
          }
        }
      }
      return true;
    },
    save() {
      this.form.customerId = this.queryData.customerId; //客户id
      this.form.invoiceType = this.selectInvoice.invoiceType;//专票/普票
      this.form.amount = this.invoiceAmount; // 合计开票金额
      this.form.actualAmount = this.totalActualAmount;//实际开票金额
      // 专票情况下 判断每个发票信息是否为空
      if (this.invoiceType == 2) {
        if (!this.selectInvoice.invoiceHeading || !this.selectInvoice.dutyParagraph || !this.selectInvoice.bank || !this.selectInvoice.invoiceTypeName || !this.selectInvoice.bankAccount || !this.selectInvoice.address || !this.selectInvoice.phone) {
          this.$message.warning('发票信息不全，需将发票信息中标记*号的字段补齐后可进行提交！')
          return false;
        }
      }
      this.form.invoiceId = this.queryData.invoiceId;
      this.form.type = 1; //1申请单 2预开票
      // 预开票
      if (this.invoicingType == 3) {
        this.form.actualAmount = this.glAmout;
        this.form.type = 2;
      }
      // 新增 
      if (!this.$route.query.status && !this.$route.query.id) {
        this.addSave();
      }
      // 编辑
      if (this.$route.query.id) {
        this.editSave();
      }
      // 关联
      if (this.$route.query.status) {
        if (this.invoiceAmount - this.totalActualAmount < 0) {
          this.$message.error('明细合计开票金额已大于可开票金额，无法提交！')
          return false;
        }
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/correlationReceipt/' + this.form.id, 'post', this.form).then(res => {
          if (res.data.code == 200) {
            this.$message.success('操作成功！');
            this.$router.push('/sfa_workOrder/InvoiceApplyList')
          } else {
            this.$message.error(res.data.message);
          }
        })
      }
      console.log(this.form, 'form')
      // 新增
    },
    // 编辑
    editSave() {
      this.form.sfaInvoiceApplyDetailsContentList.forEach(item => {
        delete item.id
      })
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/update', 'post', this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success('操作成功！');
          this.$router.push('/sfa_workOrder/InvoiceApplyList')
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    addSave() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/save', 'post', this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success('操作成功！');
          this.$router.push('/sfa_workOrder/InvoiceApplyList')
          this.form = {
            amount: 0,
            actualAmount: 0,//开票金额
            sfaInvoiceApplyDetailsList: [],
            type: this.form.type
          }
          this.glAmout = 0;
          this.invoicingType = 1;
          this.invoiceAmount = 0;
          this.queryData = {
            page: 0,
            size: 10,
          }
          this.selectedRowsList = [];
          this.dataSource = []
          this.selectRowsObj = {};
          this.selectedRowsKeys = [];
          this.selectedRowsList = [];
          this.current = 0;
          this.selectInvoice = {};
          this.pileDataSource = [];
          this.resDataSource = [];
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    save1() {
      this.form.customerId = this.queryData.customerId; //客户id
      this.form.invoiceType = this.selectInvoice.invoiceType;//专票/普票
      // 专票情况下 判断每个发票信息是否为空
      if (this.invoiceType == 2) {
        if (!this.selectInvoice.invoiceHeading || !this.selectInvoice.dutyParagraph || !this.selectInvoice.bank || !this.selectInvoice.invoiceTypeName || !this.selectInvoice.bankAccount || !this.selectInvoice.address || !this.selectInvoice.phone) {
          this.$message.warning('发票信息不全，需将发票信息中标记*号的字段补齐后可进行提交！')
          return false;
        }
      }
      this.form.invoiceId = this.queryData.invoiceId;
      if (!this.form.invoiceTypeId) {
        this.$message.warning('请选择发票类型！')
        return false;
      }
      if (this.invoicingType != 3 && this.tempSfaInvoiceApplyDetailsList.length == 0 && this.form.sfaInvoiceApplyDetailsList.length == 0) {
        this.$message.warning('请添加开票明细')
        return false;
      }
      // 预开票
      if (this.$route.query.status) {
        // 依旧选择预开票
        if (this.invoicingType == 3) {
          this.form.amount = 0;
        }
        if (this.form.actualAmount > this.glAmout) {
          this.$message.error('明细合计开票金额已大于可开票金额，无法提交！')
          return false;
        }
        this.form.actualAmount = this.glAmout;
        if (this.form.sfaInvoiceApplyDetailsList.length == 0) {
          this.form.sfaInvoiceApplyDetailsList = [];
          this.tempSfaInvoiceApplyDetailsList.forEach(item => {
            this.form.sfaInvoiceApplyDetailsList.push({
              detailCode: item.code,
              amount: item.amount,
              sfaInvoiceApplyDetailsContentList: item.sfaInvoiceApplyDetailsContentList
            })
          })
          if (!this.checkEditable(this.form.sfaInvoiceApplyDetailsList)) {
            return false;
          }
        } else {
          this.form.sfaInvoiceApplyDetailsList.forEach(item => {
            item.sfaInvoiceApplyDetailsContentList.forEach(it => {
              delete it.id
            })
          })
          if (!this.checkEditable(this.form.sfaInvoiceApplyDetailsList)) {
            return false;
          }
        }
      } else {
        // 新增 / 编辑
        console.log(this.form, 'this.formthis.form')
        if (this.invoicingType != 3) {
          if (this.form.sfaInvoiceApplyDetailsList.length == 0) {
            this.form.actualAmount = 0; //实际开票总金额
            this.form.sfaInvoiceApplyDetailsList = [];
            this.tempSfaInvoiceApplyDetailsList.forEach(item => {
              this.form.actualAmount = (this.form.actualAmount - 0) + (item.amount - 0)
              item.sfaInvoiceApplyDetailsContentList.forEach(it => {
                delete it.id
              })
              this.form.sfaInvoiceApplyDetailsList.push({
                detailCode: item.code,
                amount: item.amount,
                sfaInvoiceApplyDetailsContentList: item.sfaInvoiceApplyDetailsContentList
              })
            })
            if (!this.checkEditable(this.form.sfaInvoiceApplyDetailsList)) {
              return false;
            }
          } else {
            this.form.actualAmount = 0; //实际开票总金额
            this.form.sfaInvoiceApplyDetailsList.forEach(item => {
              this.form.actualAmount = (this.form.actualAmount - 0) + (item.amount - 0)
              item.sfaInvoiceApplyDetailsContentList.forEach(it => {
                delete it.id
              })
            })
            if (!this.checkEditable(this.form.sfaInvoiceApplyDetailsList)) {
              return false;
            }
          }
          for (let i = 0; i < this.form.sfaInvoiceApplyDetailsList.length; i++) {
            if (this.form.sfaInvoiceApplyDetailsList[i].sfaInvoiceApplyDetailsContentList.length == 0) {
              this.$message.warning('请添加发票明细！');
              return false;
            }
          }
        }
      }

      if (this.form.actualAmount > this.invoiceAmount && (this.invoicingType != 3 && !this.$route.query.status)) {
        this.$message.error('明细合计开票金额已大于可开票金额，无法提交！')
        return false;
      }

      //判断 type 1卡券 2 货堆 3宅配
      if (this.invoicingType == 1) {
        this.form.type = 1;
      }
      if (this.invoicingType == 4) {
        this.form.type = 2;
      }
      if (this.invoicingType == 5) {
        this.form.type = 3;
      }


      if (this.$route.query.status) {
        // 关联
        this.form.type = 1; //
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/correlationReceipt/' + this.form.id, 'post', this.form).then(res => {
          if (res.data.code == 200) {
            this.$message.success('操作成功！');
            this.$router.push('/sfa_workOrder/InvoiceApplyList')
          } else {
            this.$message.error(res.data.message);
          }
        })
      } else {
        // 编辑
        if (this.$route.query.id) {
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/update', 'post', this.form).then(res => {
            if (res.data.code == 200) {
              this.$message.success('操作成功！');
              this.$router.push('/sfa_workOrder/InvoiceApplyList')
            } else {
              this.$message.error(res.data.message);
            }
          })
        } else {
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/save', 'post', this.form).then(res => {
            if (res.data.code == 200) {
              this.$message.success('操作成功！');
              this.$router.push('/sfa_workOrder/InvoiceApplyList')
              this.form = {
                amount: 0,
                actualAmount: 0,//开票金额
                sfaInvoiceApplyDetailsList: [],
                type: this.form.type
              }
              this.invoiceAmount = 0;
              this.queryData = {
                page: 0,
                size: 10,
              }
              this.selectedRowsList = [];
              this.dataSource = []
              this.selectRowsObj = {};
              this.selectedRowsKeys = [];
              this.selectedRowsList = [];
              this.current = 0;
              this.selectInvoice = {};
              this.pileDataSource = [];
              this.resDataSource = [];
            } else {
              this.$message.error(res.data.message);
            }
          })
        }
      }
    },
    selectChange() {
      delete this.queryData.invoiceId;
      //获取该客户发票列表
      this.getInoviceList();
      this.getList();// 获取销售单数据
      this.getSellerList();//获取卖方主体
      this.getPileList();//获取货堆列表
      this.getResList();//获取宅配列表
    },
    selectInvoiceChange(id) {
      this.invoiceList.forEach(item => {
        if (id == item.id) {
          this.selectInvoice = item;
        }
      })
    },
    // 切换tab
    callback(key) {
      if (key == 3) {
        this.selectRowsObj = {};
        this.selectedRowsList = [];
        this.selectedRowsList1 = [];
        this.selectedRowsList2 = [];
        this.selectedRowsList3 = [];
        this.selectedRowsKeys1 = [];
        this.selectedRowsKeys2 = [];
        this.selectedRowsKeys3 = [];
        this.form.sfaInvoiceApplyDetailsList = [];
      }
      this.invoicingType = key;
    },
    // 选中销售单
    onSelectChange1(selectedRowKeys, selectedRows) {
      console.log(`selectedRowKeys1: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      this.selectedRowsKeys1 = selectedRowKeys;
      this.selectedRowsList1 = selectedRows;
      this.selectRowsObj1 = selectedRows[0];
      this.salcActualAmount()
    },
    onSelectChange2(selectedRowKeys, selectedRows) {
      console.log(`selectedRowKeys2: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      this.selectedRowsKeys2 = selectedRowKeys;
      this.selectedRowsList2 = selectedRows;
      this.selectRowsObj2 = selectedRows[0];
      this.salcActualAmount()
    },
    onSelectChange3(selectedRowKeys, selectedRows) {
      console.log(`selectedRowKeys3: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      this.selectedRowsKeys3 = selectedRowKeys;
      this.selectedRowsList3 = selectedRows;
      this.selectRowsObj3 = selectedRows[0];
      this.salcActualAmount()
    },
    // 开票详情数组
    sendCententList(data) {
      console.log(data, 'dat11111a')
      this.form.sfaInvoiceApplyDetailsContentList = data;
      this.totalActualAmount = 0;
      this.sfaInvoiceApplyDetailsContentList = data;
      data.forEach(item => {
        console.log(11111)
        this.totalActualAmount += item.money
        // this.totalActualAmount = (this.totalActualAmount + item.money).toFixed(2)
        console.log(this.totalActualAmount, 'this.totalActualAmount')
      })
    },
    // form.actualAmount
    // 合并选中列表 计算可开票合计金额
    salcActualAmount() {
      this.invoiceAmount = 0;
      this.selectedRowsList = [];
      this.form.sfaInvoiceApplyDetailsList = [];
      if (this.selectedRowsList1.length > 0) {
        this.selectedRowsList1.forEach(item => {
          this.invoiceAmount = (this.invoiceAmount - 0) + (item.surplusAmount - 0);
          item.invoicingType = 1;//销售单
          this.selectedRowsList.push(item);
          this.form.sfaInvoiceApplyDetailsList.push({
            invoicingType: item.invoicingType,
            detailCode: item.code
          });
        })
      }
      if (this.selectedRowsList2.length > 0) {
        this.selectedRowsList2.forEach(item => {
          this.invoiceAmount = (this.invoiceAmount - 0) + (item.surplusAmount - 0);
          item.invoicingType = 4;//货堆
          this.selectedRowsList.push(item);
          this.form.sfaInvoiceApplyDetailsList.push({
            invoicingType: item.invoicingType,
            detailCode: item.code
          });
        })
      }
      if (this.selectedRowsList3.length > 0) {
        this.selectedRowsList3.forEach(item => {
          this.invoiceAmount = (this.invoiceAmount - 0) + (item.surplusAmount - 0);
          item.invoicingType = 5;//宅配
          this.selectedRowsList.push(item);
          this.form.sfaInvoiceApplyDetailsList.push({
            invoicingType: item.invoicingType,
            detailCode: item.code
          });
        })
      }

      // 关联
      // if(this.$route.query.status) {
      // this.invoiceAmount =
      // }
    },
    // 卖方主体
    getSellerList() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
        this.sellerList = res.data.data;
        this.form.sellerId = this.sellerList[0].id
        this.getInoviceTypeList()
      })
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getList()
    },

    // 获取该公司发票种类
    getInoviceTypeList() {
      if (this.form.sellerId) {
        request(process.env.VUE_APP_API_BASE_URL + "sfa/invoice/type/list/" + this.form.sellerId, "get").then((res) => {
          if (res.data.code == 200) {
            this.TypeList = res.data.data;
          }
        })
      }
    },
    // 获取货堆列表
    getPileList() {
      this.pileParams.buyCustomerId = this.queryData.customerId
      return new Promise((resolve) => {
        if (this.queryData.customerId) {
          this.pileLoading = true;
          request(process.env.VUE_APP_API_BASE_URL + "sfa/order/ka/listByCustomerId", "post", this.pileParams).then((res) => {
            this.pileLoading = false;
            let data = res.data;
            this.pileDataSource = data.data.data;
            this.pileParams.page = data.data.page;
            this.pileParams.size = data.data.size;
            resolve(); // 表示 getList() 方法执行完毕
          });
        }
      });
    },
    // 获取宅配列表
    getResList() {
      this.resParams.buyCustomerId = this.queryData.customerId
      return new Promise((resolve) => {
        if (this.queryData.customerId) {
          console.log(111)
          this.resLoading = true;
          request(process.env.VUE_APP_API_BASE_URL + "sfa/order/ka/listByCustomerId", "post", this.resParams).then((res) => {
            this.resLoading = false;
            let data = res.data;
            this.resDataSource = data.data.data;
            this.resParams.page = data.data.page;
            this.resParams.size = data.data.size;
            resolve(); // 表示 getList() 方法执行完毕
          });
        }
      });
    },
    // 获取用户发票列表
    getInoviceList() {
      request(process.env.VUE_APP_API_BASE_URL + "sfa/invoice/list/" + this.queryData.customerId, "get").then((res) => {
        this.invoiceList = res.data.data;
      });
    },
    getUser() {
      // 客户列表
      request(process.env.VUE_APP_API_BASE_URL + "sfa/customer/list/all", "get").then((res) => {
        this.customerData = res.data.data;
      });
    },
    // 销售单数据
    getList() {
      return new Promise((resolve) => {
        if (this.queryData.customerId) {
          this.tabLoading = true;
          request(process.env.VUE_APP_API_BASE_URL + "sfa/saledocket/listbycustomerid", "post", this.queryData).then((res) => {
            this.tabLoading = false;
            let data = res.data;
            this.dataSource = data.data.data;
            let resultMap = new Map();
            this.dataSource.forEach(item => {
              if (resultMap.has(item.id)) {
                let existingItem = resultMap.get(item.id);
                // 检查属性是否相同
                let same = true;
                Object.keys(item).forEach(key => {
                  if (existingItem[key] !== item[key]) {
                    same = false;
                    // 如果属性值不同，则拼接到一个属性中
                    existingItem[key] = existingItem[key] + ', ' + item[key];
                  }
                });
                // 如果所有属性都相同，只保留一个对象
                if (same) {
                  resultMap.set(item.id, existingItem);
                }
              } else {
                resultMap.set(item.id, item);
              }
            });
            // 将 resultMap 转换为数组
            let mergedArray = Array.from(resultMap.values());
            this.dataSource = mergedArray;
            console.log(mergedArray);
            this.queryData.page = data.data.page;
            this.queryData.size = data.data.size;
            this.total = data.data.total;
            // this.selectedRowsList = [];
            // delete this.queryData.invoiceId

            resolve(); // 表示 getList() 方法执行完毕
          });
        }
      });
    },
    findDuplicateIds(array) {
      const idMap = {};
      const duplicates = [];
      array.forEach((item, index) => {
        if (idMap[item.id] === undefined) {
          idMap[item.id] = index;
        } else {
          if (!duplicates.some(duplicate => duplicate.id === item.id)) {
            duplicates.push({ id: item.id, indexes: [idMap[item.id], index] });
          } else {
            const existingDuplicate = duplicates.find(duplicate => duplicate.id === item.id);
            existingDuplicate.indexes.push(index);
          }
        }
      });
      return duplicates;
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    formatDateMin(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      return result;
    },
  },
};
</script>
<style scoped>
.page {
  width: 100%;
  min-height: calc(100vh - 140px);
  /* display: flex; */
  /* overflow: hidden; */
  padding: 20px;
  box-sizing: border-box;
}

.page ul {
  padding: 0;
}

.page-table {
  min-height: 40vh;
}

.page li {
  height: 100%;
}




.page-box {
  margin-left: auto;
  padding-right: 46px;
  margin-top: 10px;
}

.sale-list {
  display: flex;
  font-size: 15px;
  font-weight: 500;
}

.sale-list p {
  margin-right: 20px;
}

.page-info {
  position: absolute;
  right: 10px;
  top: 120px;
  z-index: 99999;
  padding-right: 20px;
}

::v-deep .ant-select-disabled .ant-select-selection {
  background: none;
}

::v-deep .ant-select-selection {
  border: none;
}

::v-deep .ant-select-arrow {
  display: none;
}

::v-deep .ant-select-selection-selected-value {
  color: #000;
}

::v-deep .ant-input[disabled] {
  border: 0;
  background: none;
  color: #000;
}
::v-deep .ant-form-item {
  color:#000;
}
</style>
